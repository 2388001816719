import React from "react";
import '../Estilos-Componentes/habilidades.scss'

function Habilidades(){
  return(
    <div className="hab" id="hab">
      <div className="cont-hab">
        <div className="hab1">
        <div className="hab-type">
            <h1>Habilidades💫</h1>
            <ul>
              <li>• Capacidad de tomar decisiones</li>
              <li>• Facilidad para Trabajar en equipo</li>
              <li>• Capacidad de comunicación</li>
              <li>• Adaptación frente al cambio</li>
              <li>• Gestión del Tiempo</li>
              <li>• Aprendizaje continuo</li>
              <li>• Atención al detalle</li>
            </ul>
          </div>
          <div className="hab-type-explain">
            <img src="images/1cb32e5eca87add3785208e57a3e0e3e.gif" alt="skills" />
          </div>
        </div>
          <div className="skills">
          <h1>Tecnologias</h1>
              <ul>
                <li><i className="ri-html5-fill" id="html5"></i></li>
                <li><i className="ri-css3-fill" id="css3"></i></li>
                <li><i className="fab fa-sass" id="sass"></i></li>
                <li><i className="ri-javascript-fill" id="javascript"></i></li>
                <li><i className="ri-bootstrap-fill" id="bootstrap"></i></li>
                <li><i className="fab fa-git-alt" id="git"></i></li>
                <li><i className="ri-github-fill" id="github"></i></li>
                <li><i className="ri-reactjs-line" id="reactjs"></i></li>
              </ul>
          </div>
      </div>
      
    </div>

  );

}export default Habilidades;