import React, {useState} from "react";
import '../Estilos-Componentes/nav.scss';

function Navegador(){

  const [menuActivo, setMenuActivo] = useState(false);
  const [btnMenu,setBtnMenu]=useState(false);
  const alClick = () => {
    setMenuActivo(!menuActivo);
    setBtnMenu(!btnMenu);
  };
  
  return(
    <div className="nav">
      <div className="waz">
          <a href="#header">W</a>
        </div>
      <div className="nav-container">
        <ul>
          <li><a href="#sobremi">SOBRE MI</a></li>
          <li><a href="#hab">HABILIDADES</a></li>
          <li><a href="#proy">PROYECTOS</a></li>
          <li><a href="#contact">CONTACTO</a></li>
          <li><a href="https://drive.google.com/drive/folders/16m4DI0wse4F_YN-NQwPNv-fMr89JM3WS?usp=drive_link" target="_blank" rel="noreferrer">CERTIFICADOS</a></li>
        </ul>
        <div className="menu-boton" id="menu-boton" onClick={alClick}>
        <button className={btnMenu ? 'open' : ''}>
          <span className="top-line"></span>
          <span className="middle-line"></span>
          <span className="bottom-line"></span>
        </button>
        </div>
      </div>
      <div className={`nav-despl ${menuActivo ? 'activo' : ''}`}>
      <ul>
          <li><a href="#sobremi" onClick={(event)=>{setMenuActivo(!menuActivo);setBtnMenu(!btnMenu)}}>SOBRE MI</a></li>
          <li><a href="#hab" onClick={(event)=>{setMenuActivo(!menuActivo);setBtnMenu(!btnMenu)}}>HABILIDADES</a></li>
          <li><a href="#proy" onClick={(event)=>{setMenuActivo(!menuActivo);setBtnMenu(!btnMenu)}}>PROYECTOS</a></li>
          <li><a href="#contact" onClick={(event)=>{setMenuActivo(!menuActivo);setBtnMenu(!btnMenu)}}>CONTACTO</a></li>
          <li><a href="https://drive.google.com/drive/folders/16m4DI0wse4F_YN-NQwPNv-fMr89JM3WS?usp=drive_link" target="_blank" rel="noreferrer">CERTIFICADOS</a></li>
        </ul>
      </div>
    </div>
  );
}

export default Navegador;