import React,{useState,useRef} from "react";
import '../Estilos-Componentes/contacto.scss';

function Contacto(){
  const buttonRef = useRef(null);

  const [infoName,setInfoName] = useState("");
  const [infoMail,setInfoMail] = useState("");
  const [infoMsg,setInfoMsg] = useState("");
  function handleSubmit(event){
    event.preventDefault();
    const form = new FormData(event.target);
    setInfoName(form.get('nameCli'));
    setInfoMail(form.get('mailCli'));
    setInfoMsg(form.get('msgCli'));
    console.log(infoName,infoMail,infoMsg);
    if(buttonRef.current){
      buttonRef.current.click();
    }
  }
  return (
    <div className="contact" id="contact">
      <div className="cont-contact">
      <div className="contact-titulo">
      <h1>Contactame 📨</h1>
      </div>
      <div className="formulario">
        <form action="" method="post" id="form" onSubmit={handleSubmit}>
        <label htmlFor="">Nombre Completo <span style={{color:"red"}}>*</span></label>
        <input type="text" name="nameCli" id="nameCli" placeholder="Ejm: Juan" onChange={(event)=>{setInfoName(event.target.value)}} required/>
        <label htmlFor="">Correo Electrónico <span style={{color:"red"}}>*</span></label>
        <input type="email" name="mailCli" id="mailCli" placeholder="Ejm: juansito_d@gmail.com" onChange={(event)=>{setInfoMail(event.target.value)}} required/>
        <label htmlFor="">Mensaje</label>
        <textarea name="msgCli" id="msgCli" cols="30" rows="8" onChange={(event)=>{setInfoMsg(event.target.value)}}></textarea>
        <input type="submit" value="ENVIAR MENSAJE" id="btn-enviar"/>
        </form>
        <a ref={buttonRef} href={`mailto:walter.astudillo.zevallos@gmail.com?subject=${infoName} - ${infoMail}&body=${infoMsg}`} id="mail">mailto</a>
      </div>
      </div>
    </div>
  );
}

export default Contacto;