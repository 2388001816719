import React from "react";
import '../Estilos-Componentes/footer.scss'

function Footer(){
  return (
    <div className="footer">
      <span>Designed and Created by Walter Astudillo Z.</span>
    </div>
  );
}

export default Footer;
